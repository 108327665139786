import React from "react";

import "antd/dist/reset.css";
import {
  AppContextProvider,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from "@crema";
import { BrowserRouter } from "react-router-dom";
import "./@crema/services/index";
import { GlobalStyles } from "./shared/styles/GlobalStyle";
import { Normalize } from "styled-normalize";
import EzJWTAuthProvider from "./ezapp/utils/EzJWTAuthProvider";
import { AppLayout } from "ezapp";

const App = () => (
  <AppContextProvider>
    <AppThemeProvider>
      <AppLocaleProvider>
        <BrowserRouter>
          <EzJWTAuthProvider>
            <AuthRoutes>
              <GlobalStyles />
              <Normalize />
              <AppLayout />
            </AuthRoutes>
          </EzJWTAuthProvider>
        </BrowserRouter>
      </AppLocaleProvider>
    </AppThemeProvider>
  </AppContextProvider>
);

export default App;
